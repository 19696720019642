body {
  overflow-x: hidden;
}

.site-container {
  transition: all .3s ease-in-out;
}

.site-container.active {

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0,0,0,.65);
    opacity: 0;
  }

  @media all and (max-width: 1140px) {
    transform: translateX(-400px);

    &:before {
      opacity: 1;
    }
  }

  @media all and (max-width: 499px) {
    transform: translateX(-85%);
  }
}

.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  transform: translateX(400px);
  transition: all 0.3s ease-in-out;
  background-color: #F0F0F0;

  @media all and (max-width: 1140px) {
    width: 400px;
    height: 100vh;
  }

  @media all and (max-width: 499px) {
    width: 85%;
  }

  &.is-active {
    @media all and (max-width: 1140px) {
      display: block;
      transform: translateX(0px);
    }
  }

  &.active {
    @media all and (max-width: 1140px) {
      width: 400px;
      display: flex;
    }

    @media all and (max-width: 499px) {
      width: 85%;
    }
  }

  .navigation-header {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    .logo {
      width: 120px;
    }
  }

  .navigation-close {
    flex-grow: 1;
    text-align: right;
    font-size: 28px;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    border-top: 2px solid $alternateWhite;

    a {
      //@extend .link;
      margin-bottom: 15px;
      color: $dark;
      font-size: 18px;
    }
  }

  .navigation-sublink {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 15px;
    border-bottom: 2px solid $alternateWhite;

    a {
      //@extend .link;
      margin-bottom: 15px;
      color: $dark;
      font-size: 10px;
      text-transform: uppercase;

      &.site-btn {
        color: white;
      }
    }
  }

  .navigation-footer {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    a {
      //@extend .link;
      font-size: 13px;
      padding-left: 20px;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
      }
    }
  }

  .navigation-footer-social {
    flex-grow: 1;

    a {
      padding: 0;
    }

    img {
      padding-right: 20px;
      height: 14px;
      transition: all .3s ease-in-out;
    }
  }

  .subnav-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
