// GRID
.form-cols {
  display: flex;

  .form-col {
    width: 100%;
    padding: 0 25px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .form-col-half {
    width: 50%;
  }
}

// Components
.site-form {
  .form-label {
    display: none;
  }

  .form-input-wrapper {
    position: relative;
    width: 100%;
  }

  .form-input {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 15px;
    border: 2px solid $lighter;
    font-size: 14px;
    line-height: 1;
    outline: none;

    &.file-section {
      display: flex;
      justify-content: space-between;

      @media (max-width: 630px) {
        flex-direction: column;
      }
    }
  }

  .form-textarea {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 15px;
    border: 2px solid $lighter;
    font-size: 14px;
    line-height: 1;
    outline: none;
    height: 200px;
  }

  .site-btn {
    width: 100%;
    line-height: 1.5;
  }

  .message {
    margin: 20px 0;
    padding: 20px;
    border-radius: 3px;
    color: white;
    background-color: #5fa854;
    text-align: center;

    &.error {
      background-color: #a85557;
    }
  }
}

// --------------------------------------------------
// Hubspot Override
// --------------------------------------------------
.hbspt-form {
  form {
    &,
    *,
    & * {
      box-sizing: border-box;
    }

    .hs-form-field.field {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0;
      margin-bottom: 0;

      label {
        font-size: 12px;
        letter-spacing: 0.035em;
        color: $dark;
        padding: 0;
        margin: 0;
      }

      .hs-error-msgs {
        padding: 0;
        margin: 0;

        li {
          padding: 0;
          margin: 0;
        }

        label {
          position: relative;
          top: 0;
          padding: 0;
          margin: 0;
          color: red;
        }
      }

      .input {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
      }

      input {
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        min-height: 54px;
        padding: 20px;
        margin-top: 4px;
        border: none;
        font-size: 14px;
        line-height: 1;
        outline: none;
        border-radius: 0;
        box-shadow: 0 0 40px 0 rgba(221, 221, 221, 0.5);
      }

      textarea {
        box-sizing: border-box;
        width: 100%;
        min-width: 100%;
        max-width: 631px;
        min-height: 115px;
        max-height: 230px;
        padding: 15px 20px;
        margin-bottom: 15px;
        border: 2px solid $lighter;
        font-size: 14px;
        line-height: 1;
        outline: none;
        box-shadow: none;

        @media (max-width: 1099px) {
          max-width: calc(100vw - 50px);
        }
      }
    }

    .actions {
      padding: 0;
      margin: 0;
    }

    .hs-button,
    .hs-button.primary.large {
      position: relative;
      top: 10px;
      padding: 12px 35px;
      margin: 0;
      // border-radius: 3px;
      background: $colorAccent1;
      letter-spacing: 0.055em;
      font-family: $fontTitleSerif;
      font-size: 16px;
      color: #fff;
      border: 1px solid $colorAccent1;
      box-shadow: none;

      cursor: pointer;

      transition: all 0.35s ease;

      &:hover {
        background: $colorAccent3;
        color: #fff;
        border-color: $colorAccent3;
      }
    }

    .hs_error_rollup {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translate3d( 0, 100%, 0);
    }

    .hs-error-msgs.inputs-list {
      position: absolute;
      bottom: 8px;
      left: 0;
      right: 0;
      transform: translate3d( 0, 100%, 0);
      line-height: 1;
    }
  }
}

.hubspot-contact{
  .hbspt-form form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .hs-form-field.field {
      width: 49%;

      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .hs-fieldtype-textarea.field.hs-form-field {
      width: 100%;
    }

    .hs-button,
    .hs-button.primary.large {
      position: relative;
      top: 10px;
      padding: 12px 35px;
      margin: 0;
      // border-radius: 3px;
      background: $colorAccent1;
      letter-spacing: 0.055em;
      font-family: $fontTitleSerif;
      font-size: 16px;
      color: #fff;
      border: 1px solid $colorAccent1;

      cursor: pointer;

      transition: all 0.35s ease;

      &:hover {
        background: $colorAccent3;
        color: #fff;
        border-color: $colorAccent3;
      }
    }

    .hs_error_rollup {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translate3d( 0, 100%, 0);
    }

    .hs-error-msgs.inputs-list {
      position: absolute;
      bottom: 8px;
      left: 0;
      right: 0;
      transform: translate3d( 0, 100%, 0);
      line-height: 1;
    }


    fieldset.form-columns-2,
    fieldset.form-columns-1 {
      width: 100%;
      position: relative;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      max-width: unset;
    }

    fieldset.form-columns-2 {
      .hs-form-field {
        float: left;
        width: calc(50% - 10px) !important;
        margin-right: 20px;

        &:last-child {
          margin: 0;
        }
      }
    }

    fieldset.form-columns-1 {
      .hs-form-field {
        width: 100%;
      }
    }

  }
}

.alert-success {
  background: rgba(20, 181, 96, 0.5);
  width: 100%;
  text-align: center;
  padding: 15px 20px;
  border-radius: 3px;
  margin: 0 0 10px;
  font-weight: 600;
  color: white;
}
