.hero-home {
  min-height: 100vh;
  padding: 220px 80px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('/img/todelete-hero-bg.jpg') no-repeat center center / cover;

  @media (max-width: 1400px) {
    padding: 160px 40px 80px;
  }

  @media (max-width: 1100px) {
    padding: 120px 40px 80px;
  }

  @media (max-width: 1023px) {
    padding: 120px 40px 40px;
  }

  @media (max-width: 767px) {
    padding: 120px 20px 40px;
  }

  .hero-home-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $maxWidth;

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }

  .hero-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    max-width: 700px;
    margin-right: 40px;

    @media (max-width: 1023px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 40px;
      margin-right: 0;
    }

    .hero-radios {
      display: flex;
      align-items: stretch;
      border: 2px solid white;
      border-radius: 3px;
      margin-bottom: 80px;

      @media (max-width: 1200px) {
        margin-bottom: 40px;
      }

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .hero-radio {
      width: 200px;
      text-align: center;
      font-size: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: white;
      padding: 20px;
      cursor: pointer;
      transition: all ease-out .2s;

      @media (max-width: 1299px) {
        padding: 14px 12px 12px;
      }

      @media (max-width: 1200px) {
        width: 150px;
        padding: 10px 20px;
        font-size: 14px;
      }

      @media (max-width: 767px) {
        width: 50%;
      }
    }

    input[type=radio] {
      display: none;

      &:checked + label {
        background-color: white;
        color: $colorAccent2;
      }
    }

    .hero-info {
      display: none;

      &.is-active {
        display: block;
      }
    }

    .hero-title {
      font-size: 46px;
      letter-spacing: 0;
      font-weight: 700;
      font-family: $fontTitle;
      color: white;
      text-transform: uppercase;
      margin: 0 0 20px;

      @media (max-width: 1299px) {
        font-size: 40px;
      }

      @media (max-width: 1200px) {
        font-size: 32px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
        margin: 0 0 10px;
      }
    }

    .hero-subtitle {
      display: flex;
      align-items: center;
      font-size: 16px;
      letter-spacing: 1px;
      font-weight: 700;
      color: white;
      margin: 0 0 60px;

      @media (max-width: 767px) {
        margin: 0 0 20px;
      }

      img {
        max-width: 150px;
        margin-left: 15px;
      }
    }

    .hero-cta {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      background: transparent;
      border: none;

      @media (max-width: 767px) {
        font-size: 14px;
      }

      img {
        width: 70px;
        margin-right: 10px;
        transition: all ease-out .2s;

        @media (max-width: 767px) {
          width: 50px;
        }
      }

      &:hover img {
        transform: scale(1.1);
        margin-right: 15px;
      }
    }
  }

  .hero-right {
    width: 100%;
    max-width: 460px;
    background-color: $colorAccent2;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-items: center;

    @media (max-width: 1023px) {
      max-width: 100%;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }

    .hero-form-title {
      font-size: 26px;
      color: white;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 0 10px;
      text-align: center;
      text-align: -webkit-center;

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    .hero-form-subtitle {
      font-weight: 700;
      color: white;
      text-align: center;
      text-align: -webkit-center;
      margin: 0 0 30px;

      @media (max-width: 767px) {
        font-size: 14px;
        margin: 0 0 20px;
      }
    }

    .fields-group {
      width: 100%;

      &.has-danger {
        input, select {
          width: 100%;
          margin-bottom: 5px;
        }

        .text-help {
          margin-bottom: 30px;
          color: $colorAccent1;
          font-weight: 700;
          font-size: 14px;
        }
      }

      input, select {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    input, select {
      font-size: 14px;
      font-weight: 700;
      color: white;
      background-color: #424242;
      padding: 20px;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0 0 10px;
      border-radius: 3px;
      outline: 0;
    }

    select {
      padding: 20px 50px 20px 20px;
      background: #424242 url('/img/site/icon-select.svg') no-repeat right 20px center / 12px auto;
    }

    button {
      @extend .site-btn;
      // width: 100%;
      // max-width: 180px;
      padding: 15px 50px;
      margin: 30px auto 0;

      @media (max-width: 767px) {
        max-width: 100%;
        margin: 10px 0 0;
      }
    }

    .message {
      margin: 20px 0;
      padding: 20px;
      border-radius: 3px;
      color: white;
      background-color: #5fa854;
      text-align: center;

      &.error {
        background-color: #a85557;
      }
    }
  }
}

.offers {
  display: none;
  background: #F0F0F0;
  flex-direction: column;
  align-items: center;
  padding: 80px 80px 30px 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px 60px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px 40px 20px 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px 20px;
  }

  &.is-active {
    display: flex;
  }

  .offers-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: $maxWidth;
  }

  .offer {
    position: relative;
    width: calc(33.33334% - (100px / 3));
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    overflow: hidden;

    @media (max-width: 1400px) {
      width: calc(33.33334% - (40px / 3));
      margin-bottom: 20px;
    }

    @media (max-width: 1024px) {
      width: calc(50% - 10px);
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      top: -50px;
      left: -50px;
      width: 100px;
      height: 100px;
      background-color: #F0F0F0;
      transform: rotate(45deg);

      @media (max-width: 768px) {
        top: -30px;
        left: -30px;
        width: 60px;
        height: 60px;
      }
    }

    .offer-gallery {
      width: 100%;

      img {
        width: 100%;
      }

      .flickity-prev-next-button {
        padding: 0;
        width: 40px;
        height: 50px;
        border-radius: 0;
        background: none;

        @media (max-width: 767px) {
          width: 30px;
          height: 40px;
        }

        &.previous {
          left: 0;
          background: url('/img/site/icon-left-arrow.svg') no-repeat left center / 40px auto;

          @media (max-width: 767px) {
            background-size: 30px auto;
          }
        }

        &.next {
          right: 0;
          background: url('/img/site/icon-right-arrow.svg') no-repeat right center / 40px auto;

          @media (max-width: 767px) {
            background-size: 30px auto;
          }
        }

        svg {
          display: none;
        }

        &:disabled {
          opacity: 0;
        }
      }
    }

    .offer-info {
      padding: 40px;
      background: white;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;

      @media (max-width: 768px) {
        padding: 20px;
      }

      .offer-name {
        position: relative;
        width: 100%;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 20px;
        text-align: center;
        margin: 0 0 20px;

        @media (max-width: 768px) {
          font-size: 18px;
          padding-bottom: 10px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          height: 2px;
          width: 50px;
          background-color: $colorAccent1;
        }
      }

      .offer-subtitle {
        color: #747474;
        font-weight: 700;
        margin: 0 0 40px;

        @media (max-width: 768px) {
          font-size: 14px;
          margin: 0 0 20px;
        }
      }

      .offer-button {
        @extend .site-btn;
        font-size: 14px;
        font-weight: 700;
        background-color: $colorAccent2;
        border-color: $colorAccent2;
        padding: 17px 32px;

        @media (max-width: 768px) {
          padding: 13px 30px;
        }
      }
    }
  }
}

.arguments {
  background: white url('/img/site/arguments-bg.svg') no-repeat bottom center / cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .arguments-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 0 50px;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .argument {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33334%;
    border-right: 1px solid #D8D8D8;
    padding: 60px;

    @media (max-width: 1200px) {
      padding: 60px 20px;
    }

    @media (max-width: 1024px) {
      padding: 40px 20px;
      border-right: none;
    }

    @media (max-width: 767px) {
      padding: 20px;
      width: 100%;
    }

    &:last-child {
      border-right: none;
    }

    .argument-icon {
      width: 100%;
      max-height: 100px;
      margin: 0 0 40px;
    }

    .argument-name {
      font-size: 22px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      margin: 0 0 20px;
    }

    .argument-text {
      font-weight: 700;
      margin: 0;
      color: #747474;
      text-align: center;
    }
  }

  .argument-button {
    @extend .site-btn;
  }
}

.offices {
  background: $colorAccent2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .offices-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 0 50px;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 40px;
    }
  }

  .main-title {
    color: white;
    margin: 0 0 25px;
  }

  .main-subtitle {
    color: #AEAEAE;
  }

  .offices-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &:after {
      content: '';
      position: absolute;
      width: 250px;
      height: 60%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('/img/site/icon-offices-rotate.svg') no-repeat center center / contain;
      z-index: 1;

      @media (max-width: 767px) {
        height: 100px;
      }
    }

    .office {
      width: calc(50% - 25px);
      position: relative;

      @media (max-width: 1023px) {
        width: calc(50% - 10px);
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      // &:after {
      //   content: '';
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   top: 0;
      //   left: 0;
      //   background-repeat: no-repeat;
      //   background-position: center center;
      //   background-size: 45%;
      // }

      // &:first-child:after {
      //   background-image: url('/img/site/office-montreal.png');
      // }

      // &:last-child:after {
      //   background-image: url('/img/site/office-longueuil.png');
      // }

      img {
        width: 100%;
      }
    }
  }

  .argument-button {
    @extend .site-btn;
  }
}

.testimonials {
  background: #F0F0F0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 80px 30px 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px 60px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px 40px 20px 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px 20px;
  }

  .testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: $maxWidth;
  }

  .testimonial {
    position: relative;
    width: calc(33.33334% - (100px / 3));
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    background: white;
    overflow: hidden;
    padding: 40px;

    @media (max-width: 1400px) {
      width: calc(33.33334% - (40px / 3));
      margin-bottom: 20px;
    }

    @media (max-width: 1024px) {
      width: calc(50% - 10px);
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 40px 20px;
    }

    &:after {
      content: '';
      position: absolute;
      top: -50px;
      left: -50px;
      width: 100px;
      height: 100px;
      background-color: #F0F0F0;
      transform: rotate(45deg);

      @media (max-width: 768px) {
        top: -30px;
        left: -30px;
        width: 60px;
        height: 60px;
      }
    }

    .testimonial-photo {
      height: 80px;
      margin: 0 0 10px;
    }

    .testimonial-name {
      font-size: 22px;
      font-weight: 700;
      color: $colorAccent2;
      margin: 0 0 3px;
      text-align: center;
    }

    .testimonial-job {
      color: #747474;
      font-weight: 700;
      margin: 0 0 30px;
    }

    .testimonial-text {
      color: #747474;
      font-weight: 700;
      margin: 0;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.news {
  background: white url('/img/site/news-bg.svg') no-repeat top left / auto 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  &.no-bg {
    background-image: none;
  }

  .main-title {
    margin: 0 0 50px;

    @media (max-width: 767px) {
      margin: 0 0 20px;
    }
  }

  .news-links {
    border: 2px solid $colorAccent2;
    border-radius: 3px;
    margin: 0 0 100px;
    display: flex;

    @media (max-width: 1023px) {
      margin: 0 0 40px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }

    .news-link {
      text-transform: uppercase;
      font-weight: 700;
      color: $colorAccent2;
      padding: 20px;
      width: 200px;
      text-align: center;
      transition: all ease-out .2s;
      cursor: pointer;

      @media (max-width: 1023px) {
        padding: 15px;
        font-size: 14px;
        width: 150px;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      &.is-active, &:hover {
        background-color: $colorAccent2;
        color: white;
      }
    }
  }

  .news-container {
    display: none;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 0 50px;

    @media (max-width: 1023px) {
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 10px;
    }

    &.is-active {
      display: flex;
    }
  }

  .news-thumbnail {
    position: relative;
    width: calc(33.33334% - (100px / 3));
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-right: 50px;
    margin-bottom: 50px;

    &:nth-child(3n+3) {
      margin-right: 0;
    }

    @media (max-width: 1400px) {
      width: calc(33.33334% - (40px / 3));
      margin-bottom: 20px;
      margin-right: 20px;
    }

    @media (max-width: 1023px) {
      width: calc(50% - 10px);

      &:nth-child(3n+3) {
        margin-right: 20px;
      }

      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: -50px;
      left: -50px;
      width: 100px;
      height: 100px;
      background-color: white;
      transform: rotate(45deg);

      @media (max-width: 768px) {
        top: -30px;
        left: -30px;
        width: 60px;
        height: 60px;
      }
    }

    &:hover > .news-info:after {
      transform: scale(1.1);
    }

    .news-image {
      width: 100%;
    }

    .news-info {
      width: 100%;
      position: relative;
      background-color: $colorAccent2;
      padding: 40px;
      flex-grow: 1;

      @media (max-width: 1023px) {
        padding: 40px 20px;
      }

      &:after {
        content: '';
        position: absolute;
        top: -30px;
        right: 40px;
        width: 60px;
        height: 60px;
        background: $colorAccent1 url('/img/site/icon-plus.svg') no-repeat center center / 20px;
        border-radius: 60px;
        transition: all ease-out .2s;

        @media (max-width: 1023px) {
          top: -20px;
          right: 20px;
          width: 40px;
          height: 40px;
          background-size: 15px;
        }
      }
    }

    .news-date {
      font-size: 16px;
      font-weight: 700;
      color: #C1C1C1;
      margin: 0 0 15px;

      @media (max-width: 1023px) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }

    .news-name {
      font-size: 22px;
      font-weight: 700;
      color: white;
      margin: 0;

      @media (max-width: 1023px) {
        font-size: 16px;
      }
    }
  }

  .news-button {
    @extend .site-btn;
    background-color: $colorAccent2;
    border-color: $colorAccent2;
  }
}
