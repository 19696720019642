.sidepanel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  width: 480px;
  max-width: 100%;
  height: 100vh;

  background-color: $colorAccent2;

  overflow: scroll;
  transform: translate(100%, 0);
  transition: all 0.3s ease-in;
  z-index: -1;

  &.bio {
    width: 800px;
  }

  &.opened {
    z-index: 99999;
    transform: translate(0, 0);
  }

  .sidepanel-header {
    // @extend .flex-vertical-center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    padding: 20px 30px;
    background-color: $colorAccent1;

    .sidepanel-title {
      // @extend .title5;
      flex-grow: 1;
      color: $white;
    }

    .sidepanel-close {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      color: $white;
      cursor: pointer;
    }
  }

  .sidepanel-content {
    padding: 40px 30px 10px;

    .sidepanel-subtitle {
      // @extend .title5;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    .sidepanel-text {
      @extend .main-text;
      margin-bottom: 20px;
      font-weight: 400;
      color: $dark;
    }
  }

  .hero-right {
    width: 100%;
    background-color: $colorAccent2;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-items: center;

    @media (max-width: 1023px) {
      max-width: 100%;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }

    .hero-form-title {
      font-size: 26px;
      color: white;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 0 10px;
      text-align: center;
      text-align: -webkit-center;

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    .hero-form-subtitle {
      font-weight: 700;
      color: white;
      text-align: center;
      text-align: -webkit-center;
      margin: 0 0 30px;

      @media (max-width: 767px) {
        font-size: 14px;
        margin: 0 0 20px;
      }
    }

    input, select {
      font-size: 14px;
      font-weight: 700;
      color: white;
      background-color: #424242;
      padding: 20px;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0 0 10px;
      border-radius: 3px;
      outline: 0;
    }

    select {
      padding: 20px 50px 20px 20px;
      background: #424242 url('/img/site/icon-select.svg') no-repeat right 20px center / 12px auto;
    }

    textarea {
      font-size: 14px;
      font-weight: 700;
      color: white;
      background-color: #424242;
      margin: 0 0 10px;
      padding: 20px;
      border-radius: 3px;
      outline: inherit;
      border-color: $colorAccent2;
    }

    button {
      @extend .site-btn;
      // width: 100%;
      // max-width: 180px;
      padding: 15px 50px;
      margin: 30px auto 0;

      @media (max-width: 767px) {
        max-width: 100%;
        margin: 10px 0 0;
      }
    }

    .message {
      margin: 20px 0;
      padding: 20px;
      border-radius: 3px;
      color: white;
      background-color: #5fa854;
      text-align: center;

      &.error {
        background-color: #a85557;
      }
    }
  }
}
.sidepanel-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: calc(100%);
  background: rgba(0,0,0,.0);
  z-index: -1;
  transition: all 0.3s ease-in;

  &.opened {
    z-index: 9999;
    background: rgba(0,0,0,.5);
    // cursor: url(../images/cursor.png), auto;
  }
}
