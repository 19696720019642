.hero-locations {
  position: absolute;
  bottom: 0;
  margin-top: 30px;

  @media (max-width: 1024px) {
    position: relative;
    bottom: auto;
  }

  .location {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    svg {
      width: 16px;
      margin-right: 15px;
    }

    span {
      font-family: $fontText;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
      color: $white;
    }
  }
}

.arguments-icons {
  background: white url('/img/site/arguments-bg.svg') no-repeat bottom center / cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .main-title {
    margin: 0 0 65px;
  }

  .regular-text {
    width: 100%;
    max-width: 850px;
    margin: 0 auto 100px;
    text-align: center;
    color: #747474;
  }

  .arguments-icons-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 0 85px;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .argument {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33334%;
    padding: 60px;

    @media (max-width: 1200px) {
      padding: 60px 20px;
    }

    @media (max-width: 1024px) {
      padding: 40px 20px;
      border-right: none;
    }

    @media (max-width: 767px) {
      padding: 20px;
      width: 100%;
    }

    &:last-child {
      border-right: none;
    }

    .argument-icon {
      width: 100%;
      max-height: 60px;
      margin: 0 0 40px;
    }

    .argument-label {
      font-weight: 700;
      margin: 0;
      color: $colorAccent2;
      text-align: center;
    }
  }

  .arguments-icons-button {
    @extend .site-btn;
  }
}

.study-areas {
  display: flex;
  background: #F0F0F0;
  flex-direction: column;
  align-items: center;
  padding: 80px 80px 30px 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px 60px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px 40px 20px 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px 20px;
  }

  .study-areas-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    width: 100%;
    max-width: $maxWidth;
  }

  .study-area {
    position: relative;
    width: calc(50% - (100px / 2));
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    overflow: hidden;

    @media (max-width: 1400px) {
      width: calc(50% - (40px / 2));
      margin-bottom: 20px;
    }

    @media (max-width: 1024px) {
      width: calc(50% - 10px);
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    .study-area-gallery {
      position: relative;
      width: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background: rgba($colorAccent2, .25);
      }

      img {
        width: 100%;
      }

      .flickity-prev-next-button {
        z-index: 8;
        padding: 0;
        width: 40px;
        height: 50px;
        border-radius: 0;
        background: none;

        @media (max-width: 767px) {
          width: 30px;
          height: 40px;
        }

        &.previous {
          left: 0;
          background: url('/img/site/icon-left-arrow.svg') no-repeat left center / 40px auto;

          @media (max-width: 767px) {
            background-size: 30px auto;
          }
        }

        &.next {
          right: 0;
          background: url('/img/site/icon-right-arrow.svg') no-repeat right center / 40px auto;

          @media (max-width: 767px) {
            background-size: 30px auto;
          }
        }

        svg {
          display: none;
        }

        &:disabled {
          opacity: 0;
        }
      }
    }

    .study-area-info {
      padding: 40px;
      background: white;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;

      @media (max-width: 768px) {
        padding: 20px;
      }

      .study-area-name {
        position: relative;
        width: 100%;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 20px;
        text-align: center;
        margin: 0 0 20px;

        @media (max-width: 768px) {
          font-size: 18px;
          padding-bottom: 10px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          height: 2px;
          width: 50px;
          background-color: $colorAccent1;
        }
      }

      .study-area-subtitle {
        max-width: 285px;
        margin: 0 0 40px;
        text-align: center;
        color: #747474;
        font-weight: 700;

        @media (max-width: 768px) {
          margin: 0 0 20px;
          font-size: 14px;
        }
      }
    }
  }
}

.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  background: $white;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .partners-container {
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
  }

  .partners-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;

    &:first-of-type {
      .partner {
        img {
          width: 100%;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .partner {
      width: calc(100% / 3);
      margin-bottom: 30px;

      @media (max-width: 767px) {
        width: 50%;
      }

      @media (max-width: 499px) {
        width: 100%;
      }

      img {
        display: block;
        margin: 0 auto;
        width: 200px;
      }
    }
  }
}

.search-filters {
  padding: 260px 80px 80px;
  background: $colorAccent2;

  @media (max-width: 1400px) {
    padding: 250px 40px 40px;
  }

  @media (max-width: 1299px) {
    padding: 220px 30px 40px;
  }

  @media (max-width: 1100px) {
    padding: 220px 20px 40px;
  }

  @media (max-width: 499px) {
    padding: 220px 20px 20px;
  }

  .search-filters-container {
    position: relative;
    width: 100%;
    //max-width: $maxWidth;
    margin: 0 auto;
  }

  .search-filters-form {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    @media (max-width: 499px) {
      flex-direction: column;
    }

    & > div {
      width: calc((100% / 5) - (120px / 4));
      max-width: 375px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @media (max-width: 1023px) {
        width: calc((100% / 2) - 10px);
        margin-bottom: 30px;
      }

      @media (max-width: 599px) {
        &:last-of-type {
          width: 100%;
          max-width: 100%;
        }
      }

      @media (max-width: 499px) {
        width: 100%;
        max-width: 100%;
      }
    }

    label {
      margin-bottom: 12px;
      font-family: $fontText;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: $white;
    }

    input, select {
      font-size: 16px;
      font-weight: 700;
      color: #000;
      font-family: $fontText;
      background-color: $white;
      padding: 20px;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
      border-radius: 3px;
      outline: 0;
    }

    select {
      padding: 20px 50px 20px 20px;
      background: $white url('/img/site/icon-select.svg') no-repeat right 20px center / 12px auto;
    }

    button {
      border-color: $white;
      color: $white;
      font-weight: 700;
      font-family: $fontText;

      &:hover {
        border-color: $white;
        color: $colorAccent2;
        background: $white;
      }
    }
  }
}

.search-results {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 900px;
  padding: 80px 80px 30px 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px 60px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px 40px 20px 40px;
    min-height: 700px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px 20px;
    min-height: 600px;
  }

  h2 {
    width: 100%;
    margin: 0 auto 50px;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: $colorAccent2;

    @media (max-width: 768px) {
      font-size: 18px;
      padding-bottom: 10px;
    }
  }

  .results-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
  }

  .result-item {
    position: relative;
    width: calc(33.33334% - (100px / 3));
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    overflow: hidden;

    @media (max-width: 1400px) {
      width: calc(33.33334% - (40px / 3));
      margin-bottom: 20px;
    }

    @media (max-width: 1024px) {
      width: calc(50% - 10px);
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &.is-unavailable {
      .result-item-loading,
      .result-item-error,
      .result-item-button,
      .result-item-tag{
        display: none;
      }

      .result-item-unavailable {
        display: block;
      }
    }

    &.has-error {
      .result-item-button,
      .result-item-error {
        display: block;
      }
    }

    &.is-loading {
      .result-item-loading {
        display: block;
      }
    }

    .result-item-tag {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 8;
      padding: 10px 15px;
      border-radius: 25px;
      font-family: $fontText;
      font-size: 16px;
      line-height: 19px;
      background: $white;
    }

    .result-item-gallery {
      position: relative;
      width: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background: rgba($colorAccent2, .25);
      }

      img {
        width: 100%;
      }

      .flickity-prev-next-button {
        z-index: 8;
        padding: 0;
        width: 40px;
        height: 50px;
        border-radius: 0;
        background: none;

        @media (max-width: 767px) {
          width: 30px;
          height: 40px;
        }

        &.previous {
          left: 0;
          background: url('/img/site/icon-left-arrow.svg') no-repeat left center / 40px auto;

          @media (max-width: 767px) {
            background-size: 30px auto;
          }
        }

        &.next {
          right: 0;
          background: url('/img/site/icon-right-arrow.svg') no-repeat right center / 40px auto;

          @media (max-width: 767px) {
            background-size: 30px auto;
          }
        }

        svg {
          display: none;
        }

        &:disabled {
          opacity: 0;
        }
      }
    }

    .result-item-info {
      padding: 40px;
      background: white;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;

      @media (max-width: 768px) {
        padding: 20px;
      }

      .result-item-name {
        position: relative;
        width: 100%;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 20px;
        text-align: center;
        margin: 0 0 20px;

        @media (max-width: 768px) {
          font-size: 18px;
          padding-bottom: 10px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          height: 2px;
          width: 50px;
          background-color: $colorAccent1;
        }
      }

      .result-item-subtitle {
        max-width: 285px;
        margin: 0 0 40px;
        text-align: center;
        color: #747474;
        font-weight: 700;

        @media (max-width: 768px) {
          margin: 0 0 20px;
          font-size: 14px;
        }
      }
    }

    .result-item-unavailable {
      display: none;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.875px;
      text-transform: uppercase;
      font-family: $fontText;
      color: $colorAccent1;
    }
  }

  .results-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;

    .empty-title {
      margin: 0 auto 50px;
      font-size: 32px;
      line-height: 37px;
      font-weight: 700;
      text-transform: uppercase;
      color: $colorAccent2;
    }
  }
}

.booking-back {
  padding: 40px 80px;

  @media (max-width: 1400px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }

  .back-link {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.875px;
    text-transform: uppercase;
    color: $colorAccent2;

    span {
      margin-left: 15px;
    }
  }
}

.booking-container {
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .booking-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }

  .booking-form {
    width: calc(50% - 40px);
    background-color: $colorAccent2;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-items: center;

    @media (max-width: 1023px) {
      width: 100%;
      margin-bottom: 40px;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }

    &.is-loading {
      & > button[type=submit] {
        display: none;
      }

      .result-item-loading {
        display: flex;
      }
    }

    &.has-failed {
      & > button[type=submit] {
        display: none;
      }

      .result-item-error {
        display: block;
      }
    }

    .result-item-error {
      display: none;
      width: 100%;
      font-style: normal;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.875px;
      font-family: $fontText;
      color: $colorAccent1;
    }

    .result-item-loading {
      display: none;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 19px;
      color: #fff;

      span {
        margin-left: 10px;
      }
    }

    .booking-form-title {
      font-size: 26px;
      color: white;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 0 20px;
      text-align: center;
      text-align: -webkit-center;

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    .booking-form-subtitle {
      font-weight: 700;
      color: white;
      text-align: center;
      text-align: -webkit-center;
      margin: 0 0 40px;

      @media (max-width: 767px) {
        font-size: 14px;
        margin: 0 0 20px;
      }
    }

    .message {
      margin: 20px 0 0;
      padding: 20px;
      border-radius: 3px;
      color: white;
      background-color: #5fa854;
      text-align: center;
      
      &.error {
        background-color: #a85557;
      }
    }
  }

  .booking-resume {
    width: calc(50% - 40px);
    padding: 50px 40px 40px;
    background: $white;

    @media (max-width: 1023px) {
      width: 100%;
    }

    @media (max-width: 499px) {
      padding: 40px 20px 20px;
    }

    .resume-title {
      margin: 0 0 30px;
      color: $colorAccent2;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 38px;
      font-family: $fontText;

      @media (max-width: 499px) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    .resume-img {
      display: block;
      width: 100%;
      margin-bottom: 40px;
    }

    .resume-details {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;

      p {
        margin: 0;
      }
    }

    .details-attribute {
      width: calc(50% - 30px);
      margin-bottom: 30px;

      @media (max-width: 499px) {
        width: calc(50% - 10px);
        margin-bottom: 15px;
      }

      @media (max-width: 399px) {
        width: 100%;
      }
    }

    .attribute-label {
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #747474;

      @media (max-width: 499px) {
        font-size: 12px;
      }
    }

    .attribute-value {
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 1px;
      color: $colorAccent2;

      @media (max-width: 499px) {
        font-size: 16px;
      }
    }
  }

  input, select, textarea {
    font-size: 14px;
    font-weight: 700;
    color: white;
    background-color: #424242;
    padding: 20px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0 0 30px;
    border-radius: 3px;
    outline: 0;
    resize: none;
  }

  select {
    padding: 20px 50px 20px 20px;
    background: #424242 url('/img/site/icon-select.svg') no-repeat right 20px center / 12px auto;
  }

  button,
  a {
    @extend .site-btn;
    width: 100%;
    // max-width: 180px;
    padding: 15px 50px;
    margin: 0 auto;

    @media (max-width: 767px) {
      max-width: 100%;
      margin: 10px 0 0;
    }
  }

  .fields-group {
    width: 100%;

    &.has-danger {
      input, select, textarea {
        width: 100%;
        margin-bottom: 5px;
      }

      .text-help {
        margin-bottom: 30px;
        color: $colorAccent1;
        font-weight: 700;
        font-size: 14px;
      }
    }

    input, select, textarea {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

.confirmation-container {
  padding: 80px 40px 130px;

  @media (max-width: 767px) {
    padding: 40px 20px 60px;
  }

  .confirmation-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 780px;
    min-height: 700px;
    background: $white;
  }

  .confirmation-title {
    margin: 60px auto 25px;
    font-family: $fontText;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $colorAccent2;
  }

  .confirmation-text {
    @extend .regular-text;

    max-width: 300px;
    margin: 0 auto 50px;
    text-align: center;
    color: $colorAccent2;
  }

  .confirmation-btn {
    background: $colorAccent2;
    border-color: $colorAccent2;
  }
}
