.about-hero {
  height: calc(100vh - 80px);
  max-height: 840px;
  display: flex;
  align-items: center;
  padding: 80px;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  &.small {
    max-height: 400px;
  }

  .hero-title {
    @extend .main-title;
    text-align: left;
    color: white;
    margin: 0;
    max-width: $maxWidth;
  }
}

.submenu {
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  background-color: $colorAccent2;

  .submenu-wrapper {
    width: 100%;
    max-width: $maxWidth;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .submenu-link {
    font-size: 18px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    margin-right: 30px;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 5px;
      font-size: 16px;
    }

    &:last-child {
      margin-right: 0;

      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }
}

.about-members {
  background: white;
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .about-members-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
  }

  .main-text {
    font-weight: 700;
    color: #747474;
    margin: 0 auto 80px;
    max-width: 800px;
    text-align: center;

    @media (max-width: 767px) {
      margin: 0 auto 40px;
    }
  }

  .members {
    width: 100%;
    border: 1px solid #C1C1C1;

    .member {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid #C1C1C1;
      padding: 25px 50px;

      @media (max-width: 1210px) {
        padding: 20px;
      }

      &:last-child {
        border-bottom: 0;
      }

      &.is-hidden {
        display: none;
      }

      .memberLogo {
        width: 25%;
        display: flex;

        @media (max-width: 1023px) {
          width: 100%;
        }

        img {
          // width: 100%;
          max-width: 200px;
          margin: 0 auto;
          // max-height: 66px;
        }
      }

      .memberInfo {
        width: 30%;

        @media (max-width: 1023px) {
          width: 50%;
          margin: 10px 0 20px;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin: 10px 0;
        }

        strong {
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
        }
        div {
          font-weight: 700;
        }
      }

      .memberWebsite {
        width: 15%;

        @media (max-width: 1023px) {
          width: 100%;
        }

        @media (max-width: 767px) {
          margin-top: 10px;
        }

        a {
          display: flex;
          align-items: center;
          color: $colorAccent1;

          img {
            height: 30px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

#pagination-members {
  &.is-hidden {
    display: none;
  }
}

.about-partners {
  background-color: #F0F0F0;
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .about-partners-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
  }

  .categories-radios {
    display: flex;
    align-items: stretch;
    border: 2px solid $colorAccent2;
    border-radius: 3px;
    margin-bottom: 80px;

    @media (max-width: 1200px) {
      margin-bottom: 40px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
      flex-direction: column;
    }
  }

  .categories-radio {
    min-width: 200px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $colorAccent2;
    padding: 20px;
    cursor: pointer;
    transition: all ease-out .2s;

    @media (max-width: 1200px) {
      min-width: 150px;
      padding: 10px 20px;
      font-size: 14px;
    }

    @media (max-width: 767px) {
      min-width: 100%;
    }
  }

  input[type=radio] {
    display: none;

    &:checked + label {
      background-color: $colorAccent2;
      color: white;
    }
  }

  .partner-section {
    width: 100%;
    display: none;
    flex-wrap: wrap;
    justify-content: stretch;

    &.is-active {
      display: flex;
    }
  }

  .partner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 250px;
    background-color: white;
    border-right: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    padding: 20px;

    @media (max-width: 1200px) {
      height: 200px;
    }

    @media (max-width: 1024px) {
      height: 150px;
    }

    @media (max-width: 1023px) {
      width: 33.33334%;
    }

    @media (max-width: 767px) {
      width: 50%;
      height: 100px;
    }

    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.about-jobs {
  background: white;
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .about-jobs-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
  }

  .careers-listing {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #E6E6E6;
  }

  .careers-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E6E6;
    padding: 30px 20px;

    @media (max-width: 900px) {
      padding: 20px;
      flex-wrap: wrap;
    }

    &:nth-child(even) {
      background-color: #F0F0F0;
    }
  }

  .careers-link {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: $colorAccent1;
    border: 2px solid $colorAccent1;
    font-weight: 700;
    transition: all ease-out .2s;

    @media (max-width: 900px) {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }

    &:hover {
      background-color: $colorAccent1;
      color: white;
    }
  }

  .careers-infos {
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1180px) {
      width: 400px;
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      width: 250px;
    }

    @media (max-width: 900px) {
      width: auto;
    }

    & > .careers-type, & > .careers-location {
      width: 50%;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .2px;
      color: #6D6D6D;
      font-weight: 400;
      margin: 0;

      @media (max-width: 1023px) {
        margin: 5px 0;
        width: 100%;
      }
    }
  }

  .careers-name {
    flex-grow: 1;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: 900px) {
      width: 100%;
      margin: 0 0 20px;
    }
  }
}

.contact-form-wrapper {
  background-color: white;

  .contact-form {
    width: 100%;
    max-width: 900px;
    padding: 70px 20px;
    margin: 0 auto;

    @media(max-width: 700px) {
      padding: 40px 20px;
    }
  }
}
