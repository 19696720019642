.space-offers {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .space-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: $maxWidth;
  }

  .offers-galleries {
    width: calc(50% - 40px);

    @media (max-width: 1400px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 1024px) {
      width: 100%;
      margin-bottom: 40px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .offers-gallery {
    position: sticky;
    top: 5px;
    width: 100%;
    display: none;

    &.is-active {
      display: block;
    }

    img {
      width: 100%;
    }

    .flickity-prev-next-button {
      padding: 0;
      width: 40px;
      height: 50px;
      border-radius: 0;
      background: none;

      @media (max-width: 767px) {
        width: 30px;
        height: 40px;
      }

      &.previous {
        left: 0;
        background: url('/img/site/icon-left-arrow.svg') no-repeat left center / 40px auto;

        @media (max-width: 767px) {
          background-size: 30px auto;
        }
      }

      &.next {
        right: 0;
        background: url('/img/site/icon-right-arrow.svg') no-repeat right center / 40px auto;

        @media (max-width: 767px) {
          background-size: 30px auto;
        }
      }

      svg {
        display: none;
      }

      &:disabled {
        opacity: 0;
      }
    }
  }

  .offers-descriptions {
    width: calc(50% - 40px);

    @media (max-width: 1400px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .offers-description {
    margin-bottom: 3px;
    border-radius: 3px;
    overflow: hidden;
    transition: max-height ease-out .2s;
    max-height: 67px;

    @media (max-width: 767px) {
      max-height: 51px;
    }

    &.is-active {
      max-height: 100%;

      .offers-desc-title {
        background-color: $colorAccent2;

        img {
          transform: rotate(90deg);
        }
      }
    }

    .offers-desc-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      letter-spacing: .8px;
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      background-color: #747474;
      padding: 20px 30px;
      margin: 0;
      cursor: pointer;

      @media (max-width: 767px) {
        font-size: 14px;
        padding: 15px;
      }

      img {
        transition: transform ease-out .2s;
      }
    }

    .offers-desc-text {
      background-color: #F0F0F0;
      padding: 30px;
      color: #585858;

      @media (max-width: 767px) {
        padding: 15px;
      }

      p {
        margin: 0 0 30px;

        @media (max-width: 767px) {
          margin: 0 0 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.space-features {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 80px 80px;

  @media (max-width: 1400px) {
    padding: 0 40px 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 0 40px 40px;
  }

  @media (max-width: 767px) {
    padding: 0 20px 40px 20px;
  }

  .features-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: $maxWidth;
  }

  .features {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }

  .feature-item {
    position: relative;
    width: 33.33334%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1023px) {
      width: 100%;
      flex-direction: row;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &:nth-child(3n+2) {
      .feature-info {
        top: 0;
        bottom: 50%;
        @media (max-width: 1023px) {
          bottom: 0;
        }

        &:before {
          transform: translateX(-50%) rotate(180deg);
          top: initial;
          bottom: -34px;
        }
      }
    }

    @media (max-width: 1023px) {
      &:nth-child(2n+2) {
        .feature-info {
          top: 0;
          bottom: 0;
          left: 0;
          right: 50%;

          &:before {
            left: initial;
            right: -35px;
            transform: translateY(-50%) rotate(90deg);

            @media (max-width: 767px) {
              left: 50%;
              top: -24px;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  .feature-image {
    width: 100%;

    @media (max-width: 1023px) {
      width: 50%;
      height: 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
      height: 100%;

      &:nth-child(2) {
        display: none;
      }
    }
  }

  .feature-info {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    background-color: $colorAccent2;

    @media (max-width: 1023px) {
      left: 50%;
      top: 0;
    }

    @media (max-width: 767px) {
      position: relative;
      left: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: -34px;
      left: 50%;
      transform: translateX(-50%);
      width: 70px;
      height: 35px;
      background: url('/img/site/icon-chevron-top.svg') no-repeat bottom center / contain;

      @media (max-width: 1023px) {
        width: 50px;
        height: 25px;
        top: 50%;
        left: -35px;
        transform: translateY(-50%) rotate(270deg);
      }

      @media (max-width: 767px) {
        left: 50%;
        top: -24px;
        transform: translateX(-50%);
      }
    }
  }

  .feature-info-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .feature-title {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    margin: 0 0 30px;
    text-align: center;

    @media (max-width: 1280px) {
      font-size: 18px;
      margin: 0 0 20px;
    }
  }

  .feature-text {
    color: #C1C1C1;
    text-align: center;
    max-width: 330px;
    margin: 0 auto;
    // word-break: break-word;

    @media (max-width: 1280px) {
      font-size: 14px;
    }
  }
}

.space-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 768px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 20px;

  @media(max-width: 1024px) {
    height: 450px;
  }

  @media(max-width: 767px) {
    height: 350px;
  }

  .main-title {
    color: white;
  }
}
