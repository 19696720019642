// --------------------------------------------------
// Footer
// --------------------------------------------------

.main-footer {
  .footer-contact {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    background-color: $dark;
    width: 66.66667%;

    @media (max-width: 1200px) {
      position: relative;
      width: 100%;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .regular-text {
      display: inline-block;
      font-size: 22px;
      letter-spacing: 1px;
      color: $white;
      text-transform: uppercase;
      margin-right: 35px;

      @media (max-width: 767px) {
        margin-right: 0;
        margin-top: 0;
      }

    }
    .site-btn {
      display: inline-block;
      padding: 10px 30px;
      border-radius: 0;

    }
  }
  .footer-top {
    display: flex;
    position: relative;
    align-items: stretch;
    flex-wrap: wrap;

    .footer-top-map {
      width: 66.66667%;

      @media (max-width: 1200px) {
        width: 100%;
        height: 45vw;
      }

      @media (max-width: 767px) {
        height: 100vw;
      }

      .gm-style {
        height: calc(100% - 115px) !important;

        @media (max-width: 1200px) {
          height: 100% !important;
        }
      }
    }

    .footer-top-addresses {
      width: 33.33334%;
      display: flex;
      flex-direction: column;

      @media (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .footer-top-address {
        padding: 160px 80px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @media (max-width: 1400px) {
          padding: 100px 40px;
        }

        @media (max-width: 1200px) {
          width: 100%;
          padding: 40px;
        }

        @media (max-width: 767px) {
          padding: 40px 20px;
        }

        .footer-top-name {
          font-size: 22px;
          letter-spacing: 1px;
          color: white;
          margin: 0 0 20px;
          text-transform: uppercase;
        }

        .footer-top-text {
          font-size: 18px;
          letter-spacing: 1.1px;
          color: white;
          margin: 0 0 30px;
        }

        .footer-top-informations {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .footer-top-information {
            display: flex;
            align-items: center;
            font-size: 16px;
            letter-spacing: 1px;
            color: white;
            margin-right: 40px;
            margin-bottom: 20px;

            &:last-child {
              margin-right: 0;
            }

            img {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

  .footer-medium {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    .footer-medium-left {
      width: 33.33334%;
      background-color: $colorAccent2;
      display: flex;
      justify-content: center;
      align-items: center;

      &.is-half {
        width: 50%;
        padding: 80px 20px;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      @media (max-width: 1200px) {
        width: 100%;
        padding: 80px 20px;
      }

      img {
        width: 100%;
        max-width: 290px;
        max-height: 330px;
      }
    }

    .footer-medium-text {
      font-size: 22px;
      letter-spacing: 1px;
      color: white;
      text-transform: uppercase;
      margin: 0 0 30px;
      width: 100%;

      @media (max-width: 767px) {
        font-size: 16px;
        margin: 0 0 20px;
      }
    }

    .footer-medium-center {
      width: 33.33334%;
      padding: 120px 20px;
      background-color: #434343;
      display: flex;
      justify-content: center;
      align-items: center;

      &.is-half {
        width: 50%;
        text-align: center;

        @media (max-width: 1200px) {
          width: 50%;
          padding: 80px 20px;
        }

        @media (max-width: 767px) {
          width: 100%;
          padding: 40px 20px;
        }

        img {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
        }

        .site-btn {
          margin: 0 auto;
        }
      }

      @media (max-width: 1200px) {
        width: 50%;
        padding: 80px 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 40px 20px;
      }

      .footer-medium-socials {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 420px;

        @media (max-width: 1400px) {
          max-width: 300px;
        }
      }

      .footer-medium-social {
        width: 90px;
        height: 90px;
        border-radius: 90px;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color ease-out .2s;

        @media (max-width: 1400px) {
          width: 60px;
          height: 60px;
          border-radius: 60px;
        }

        &:hover {
          background-color: $colorAccent1;
        }

        img {
          width: 100%;
          max-width: 32px;
          max-height: 32px;

          @media (max-width: 1400px) {
            max-width: 24px;
            max-height: 24px;
          }
        }
      }
    }

    .footer-medium-right {
      width: 33.33334%;
      padding: 120px 20px;
      background-color: #F0F0F0;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1200px) {
        width: 50%;
        padding: 80px 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 40px 20px;
      }

      .footer-medium-text {
        color: $colorAccent2;
      }

      .footer-medium-form {
        width: 100%;
        max-width: 375px;

        input[type=email], input[type=tel] {
          font-size: 14px;
          letter-spacing: 0;
          color: white;
          padding: 20px;
          background-color: $colorAccent2;
          border: 0;
          border-radius: 3px;
          width: 100%;
          margin: 0 0 10px;
          outline: 0;
        }

        button[type=submit] {
          @extend .site-btn;
          width: 100%;
          max-width: 180px;
          margin: 30px 0 0;

          @media (max-width: 767px) {
            margin: 0;
            max-width: 100%;
          }
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 20px 80px;

    @media (max-width: 1024px) {
      padding: 20px;
    }

    @media (max-width: 520px) {
      flex-direction: column;
    }

    .footer-bottom-text {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0;
      font-weight: 600;
      color: $colorAccent2;
      margin: 0;
    }
  }
}
