// --------------------------------------------------
// Title
// --------------------------------------------------

b,
strong {
  font-weight: 500;
}

.main-title {
  font-size: 46px;
  letter-spacing: 0;
  font-family: $fontTitle;
  color: $colorAccent2;
  margin: 0 0 80px;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 1299px) {
    font-size: 40px;
  }

  @media (max-width: 1024px) {
    margin: 0 0 40px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
  }
}

.main-subtitle {
  font-size: 16px;
  color: #747474;
  font-weight: 700;
  text-align: center;
  max-width: 580px;
  margin: 0 auto 50px;

  @media (max-width: 1024px) {
    margin: 0 auto 40px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.big-title {
  font-family: $fontTextLight;
  font-size: 36px;
  line-height: 1;
  color: $colorAccent1;
  margin-bottom: 45px;
}

.medium-title {
  font-family: $fontTitleSerif;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.54px; // Doublon
  line-height: 32px;
  color: $colorAccent1;
  letter-spacing: 0.055em;
}

.medium-title.with-child {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  span {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;

    @media (max-width: 767px) {
      margin-bottom: 5px;
    }
  }
}

.small-title {
  font-family: $fontTitle;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.89px;
  line-height: 22px;
  color: $colorAccent1;

  @media screen and (min-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
  }
}

// --------------------------------------------------
// text-emphasis: dot;
// --------------------------------------------------

.regular-text {
  font-family: $fontTextLight;
  font-size: 16px;
  letter-spacing: -0.055px;
  line-height: 1.5;
  color: #90A0B0;
  margin-bottom: 20px;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }

  strong {
    font-weight: 600;
    color: #77899a;
  }

  a {
    color: $colorAccent3;
    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent1;
    }
  }

  &.is-centered {
    width: 100%;
    text-align: center;
    letter-spacing: 0.035em;
    font-size: 18px;
  }
}

.text-input {
  font-family: $fontTextLight;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 22px;
  color: $dark;

  @media screen and (min-width: 1440px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.small-text {
  font-family: $fontTitle;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 17px;
  color: $dark;

  @media screen and (min-width: 1440px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.links {
  font-family: $fontTitle;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 14px;
  color: $colorAccent2;

  @media screen and (min-width: 1440px) {
    font-size: 24px;
    line-height: 16px;
  }
}

.button-text {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.27px;
  line-height: 11px;

  @media screen and (min-width: 1440px) {
    font-size: 13px;
    line-height: 13px;
  }
}

.pagination {
  font-family: $fontTitle;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  color: $dark;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// --------------------------------------------------
// Extra
// --------------------------------------------------

.light-typo {
  color: #fff;
}

.lightblue-typo {
  color: $colorAccent2;
}

.centered {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.separator-content-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 989px) {
    max-width: 700px;
    margin: 0 auto;
  }
}

.is-separator {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -75px;
    transform: translate3d(-100%, 0, 0);
    backface-visibility: hidden;
    filter: blur(0);
    width: 60%;
    height: 1px;
    background: rgba(255, 255, 255, 0.20);

    @media only screen and (max-width: 699px) {
      left: -45px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -75px;
    transform: translate3d(100%, 0, 0);
    backface-visibility: hidden;
    filter: blur(0);
    width: 60%;
    height: 1px;
    background: rgba(255, 255, 255, 0.20);

    @media only screen and (max-width: 699px) {
      right: -45px;
    }
  }
}

.is-section-devider,
.posts-list  .medium-title.is-section-devider,
.initiatives-list  .medium-title.is-section-devider {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  color: $colorAccent1;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    backface-visibility: hidden;
    filter: blur(0);
    width: 100%;
    height: 1px;
    background: $colorAccent1;
  }

  .medium-title {
    font-family: $fontTitleSerif;
  }

  span {
    position: relative;
    z-index: 2;
    display: inline-block;
    padding-right: 20px;
    background: #fff;

    @media only screen and (max-width: 699px) {
      display: block;
      margin: 0 auto;
      padding-left: 20px;
      font-size: 18px;
    }
  }

  .site-btn {
    position: relative;
    background: #fff;
    padding-left: 55px;
    padding-right: 55px;
    z-index: 2;

    &:hover {
      background: $colorAccent1;
    }

    @media only screen and (max-width: 699px) {
      display: none;
    }
  }
}

.is-grey {

  .is-section-devider,
  .posts-list  .medium-title.is-section-devider,
  .initiatives-list  .medium-title.is-section-devider {
    span,
    .site-btn {
      background: #FCFCFF;
    }

    .site-btn {
      &:hover {
        background: $colorAccent1;
      }
    }
  }
}


ul {
  li {
    line-height: 2;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      line-height: 2;
    }
  }
}
