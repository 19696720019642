.hero-split {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 75vh;

  @media(max-width: 1023px) {
    height: 50vh;
  }

  @media(max-width: 767px) {
    flex-direction: column;
  }

  .split-side {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;
    padding: 40px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media(max-width: 767px) {
      width: 100%;
      height: 100%;
    }

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: calc(50% - 1px);
      width: 2px;
      height: 35%;
      background-color: white;

      @media(max-width: 767px) {
        display: none;
      }
    }
  }

  .split-title {
    font-size: 46px;
    font-family: $fontTitle;
    font-weight: 700;
    margin: 0 0 25px;

    @media(max-width: 1200px) {
      margin: 0 0 10px;
    }

    @media(max-width: 1023px) {
      font-size: 32px;
    }
  }

  .split-text {
    margin: 0;
    text-align: center;
  }
}

.spaces {
  background: white;
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 80px 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .spaces-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
  }

  .space {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 40px;

    @media(max-width: 1023px) {
      flex-direction: column;
    }

    @media(max-width: 767px) {
      margin: 0;
    }

    &:last-child {
      margin-bottom: 0;
      flex-direction: row-reverse;

      @media(max-width: 1023px) {
        flex-direction: column;
      }

      .space-images {
        padding: 80px 160px 0 0;

        @media(max-width: 1400px) {
          padding: 40px 80px 0 0;
        }

        @media(max-width: 767px) {
          padding: 40px 10px 0 0;
        }
      }

      .space-name {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        left: 160px;
        bottom: 80px;

        @media(max-width: 1400px) {
          left: 80px;
          bottom: 40px;
        }

        @media(max-width: 767px) {
          left: 10px;
          bottom: 40px;
        }
      }

      .space-real-name-flex {
        align-items: flex-end;

        @media(max-width: 767px) {
          align-items: flex-start;
        }
      }
    }
  }

  .space-images {
    position: relative;
    width: calc(50% - 20px);
    padding: 80px 0 0 160px;

    @media(max-width: 1400px) {
      padding: 40px 0 0 80px;
    }

    @media(max-width: 1023px) {
      width: 100%;
    }

    @media(max-width: 767px) {
      padding: 40px 0 0 10px;
    }
  }

  .space-gallery {
    position: relative;
    z-index: 10;
    width: 100%;

    img {
      width: 100%;
    }

    .flickity-prev-next-button {
      padding: 0;
      width: 40px;
      height: 50px;
      border-radius: 0;
      background: none;

      @media (max-width: 767px) {
        width: 30px;
        height: 40px;
      }

      &.previous {
        left: 0;
        background: url('/img/site/icon-left-arrow.svg') no-repeat left center / 40px auto;

        @media (max-width: 767px) {
          background-size: 30px auto;
        }
      }

      &.next {
        right: 0;
        background: url('/img/site/icon-right-arrow.svg') no-repeat right center / 40px auto;

        @media (max-width: 767px) {
          background-size: 30px auto;
        }
      }

      svg {
        display: none;
      }

      &:disabled {
        opacity: 0;
      }
    }
  }

  .space-name {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 160px;
    bottom: 80px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media(max-width: 1400px) {
      right: 80px;
      bottom: 40px;
    }

    @media(max-width: 767px) {
      right: 10px;
      bottom: 40px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($colorAccent2, .75);
    }
  }

  .space-real-name {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 50px 80px;
    font-size: 36px;
    font-family: $fontTitle;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    color: white;
    z-index: 3;

    @media(max-width: 1400px) {
      padding: 15px 40px;
    }

    @media(max-width: 767px) {
      padding: 10px 20px;
      font-size: 16px;
    }
  }

  .space-real-name-flex {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    transform: rotate(270deg);

    @media(max-width: 767px) {
      transform: rotate(0deg);
      justify-content: flex-start;
    }
  }

  .space-info {
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $colorAccent2;
    text-align: left;
    padding: 40px;

    @media(max-width: 1023px) {
      width: 100%;
      padding: 40px 0;
    }
  }

  .space-title {
    font-size: 36px;
    line-height: 46px;
    font-family: $fontTitle;
    font-weight: 700;
    margin: 0 0 40px;
    text-transform: uppercase;

    @media(max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
      margin: 0 0 20px;
    }
  }

  .space-text {
    color: #747474;
    font-weight: 700;
    margin: 0 0 30px;
  }

  .space-list {
    margin: 0 0 40px;
    padding: 0;
    text-align: left;
    width: 100%;
    list-style: none;
    font-weight: 700;

    li {
      &:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border: 2px solid $colorAccent1;
        border-radius: 12px;
        margin-right: 20px;
      }
    }
  }

  .site-btn {
    align-self: flex-start;
  }
}
