// --------------------------------------------------
// Header
// --------------------------------------------------

.main-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 40px 80px;
  z-index: 10;

  @media (max-width: 1400px) {
    padding: 40px;
  }

  @media (max-width: 1299px) {
    padding: 30px;
  }

  @media (max-width: 1100px) {
    padding: 20px;
  }

  &.for-study-area {
    &.not-home {
      .header-container {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      .header-logo {
        position: relative;
        top: auto;
        left: auto;

        img {
          height: auto;
          width: 125px;

          @media (max-width: 1100px) {
            height: auto;
            width: 100px;
          }
        }
      }
    }

    .header-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .header-logo {
      position: absolute;
      top: 0;
      left: 0;

      img {
        height: 200px;

        @media (max-width: 1100px) {
          height: 80px;
        }
      }
    }

    .header-right .header-phone {
      & > :not(:last-child) {
        @media (max-width: 599px) {
          display: none;
        }
      }

      @media (max-width: 1024px) {
        display: block;
      }
    }


  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-logo {
    img {
      height: 20px;
    }
  }

  .header-nav {
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }

    .header-link {
      position: relative;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      margin: 0 20px;

      @media (max-width: 1400px) {
        font-size: 14px;
        margin: 0 10px;
      }

      &.is-active:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: white;
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .header-phone {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      margin: 0 20px 0 0;

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .site-btn {
      @media (max-width: 1400px) {
        padding: 10px 20px;
        font-size: 12px;
      }

      @media (max-width: 1024px) {
        display: none;
      }

      &.for-study-area {
        padding: 15px 30px;

        @media (max-width: 1400px) {
          padding: 10px 20px;
          font-size: 12px;
        }

        @media (max-width: 1024px) {
          display: inline-block;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      &.for-mobile {
        display: none;
        align-items: center;

        @media (max-width: 767px) {
          display: inline-flex;
        }

        svg {
          width: 20px;
        }

        span {
          margin-left: 8px;
        }
      }
    }

    .mobile-menu-trigger {
      display: none;
      width: 30px;
      height: 30px;
      background: url('/img/site/icon-burger.svg') no-repeat center center / contain;

      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
}
