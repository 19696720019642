// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitleSerif: 'clarendon-text-pro', serif;
$fontTitle: 'clarendon-text-pro', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontText: 'DIN Alternate', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontTextLight: 'DIN Alternate', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Accents Colors
$colorAccent1: #E52713;
$colorAccent2: #2F2F2F;
$colorAccent3: #49a9d5;

// Blocks and Text
$dark: #262626;
$grey: #EBEBEB;
$light: #A0ACB9;
$lighter: #E5EAEF;
$alternateWhite: #f6f8fc;
$white : #fff;

$maxWidth: 1440px;


