// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: auto;
  font-size: 62.5%;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $fontText;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1px;
  background-color: #F0F0F0;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #C2C7D0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #C2C7D0;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #C2C7D0;
}
:-moz-placeholder { /* Firefox 18- */
  color: #C2C7D0;
}

.hide-for-screen {
  font-size: 0;
}

.bg-grey {
  background: #F0F0F0;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 3px;
  border: 3px solid $colorAccent1;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $colorAccent1 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.site-gap {
  padding: 70px;
  background: $colorAccent2;

  @media (max-width: 1299px) {
    padding: 55px;
  }

  @media (max-width: 1024px) {
    padding: 35px;
  }

  &.not-home {
    padding: 120px;

    @media (max-width: 1299px) {
      padding: 110px;
    }

    @media (max-width: 1099px) {
      padding: 85px;
    }

    //@media (max-width: 1024px) {
    //  padding: 35px;
    //}
  }
}
